<template>
    <div class="collect">
        <div class="head">
            <div class="title">我的收藏</div>
        </div>
        <div class="collectList f-w" v-if="list && list.length">
            <div class="collectItem f-cl hover" v-for="(item, index) of list" :key="index"
                @click="toDetail(item.openSearchFlag, item.productId)">
                <!-- <div v-if="item.openSearchFlag == 'Y'"> -->
                <el-image v-if="item.openSearchFlag == 'Y'" class="img" :src="item.img1Url"></el-image>
                <div v-else style="background: #C7C7C7;height: 220px;border-radius: 20px 20px 0 0;cursor: default;"></div>
                <div v-if="item.openSearchFlag == 'Y'" class="favorites-info f-cl-b">
                    <span class="name">{{ item.productName }}</span>
                    <span v-if="item.productNumber" class="num">商品编号：{{ item.productNumber }}</span>
                    <div class="f-c-b infoBox">
                        <span class="price">{{ item.unitPrice ? (item.valuationCurrency + item.unitPrice) : '暂无报价'
                        }}</span>
                    </div>
                </div>
                <div v-else class="f-c-b removedGoods">
                    <span>{{ item.productName }}</span>
                    <i @click="uncollect(item.productId)" class="el-icon-delete"></i>
                </div>
                <div v-if="item.openSearchFlag == 'Y'" class="cornermark">{{ item.productTypeStr }}</div>



            </div>
            <Pagination :margin="'0px'" :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                @pagination="fetchData" />
        </div>
        <el-empty v-else description="暂无内容"></el-empty>
    </div>
</template>
<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { UnitAccount } from "@/utils/attributeValue1Options";
export default {
    name: '',
    mixins: [PaginationRetention],
    inject: ['reload'],
    data() {
        return {
            list: [],
        }
    },
    created() { },
    methods: {
        getDate() {
            this.findProductFavoritesList(this.paging, this.pagingSize)
        },
        // 收藏列表
        findProductFavoritesList(page = this.page, size = this.size) {
            let that = this;
            that.$http.findProductFavoritesList({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    that.list = res.data.records;
                    that.list.forEach(item => {
                        item.productTypeStr = UnitAccount.getproductType(item.productType)
                    })
                    that.total = res.data.total;
                    that.currentPage = res.data.current;
                }
            })
        },
        toDetail(openSearchFlag, productId) {
            openSearchFlag == 'Y' ? this.$router.push({
                name: 'ProductDetail',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id: productId
                    }))
                }
            }) : ''
        },
        uncollect(productId){
            let that = this;
            that.$http.addCancelProductFavorites({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                productId: productId,
                pfState: 'N'
            }).then(res => {
                if (res.code == 200) {
                    that.$common.message(res.data, 'success')
                    that.getDate();
                }
            })
        }
    },
    // beforeDestroy() {
    //     this.$removeStorage('fetchDataSize')
    //     this.$removeStorage('fetchDataPage')
    // }
}
</script>
<style lang="less" scoped>
.collect {
    .head {
        height: 40px;
        padding-bottom: 24px;
        border-bottom: 1px solid #F2F2F2;

        .title {
            // font-size: 24px;
            // font-weight: 500;
            // color: #333333;
            // height: 40px;
            // line-height: 40px;
            font-size: 24px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #333333;
            line-height: 40px;
            // padding-bottom: 24px;
            font-weight: bold;
            text-align: left;
        }

        .delBtn {
            width: 80px;
            height: 40px;
            border-radius: 5px;
            background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%);
            text-align: center;
            line-height: 40px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }

    .collectList {
        margin-top: 20px;
        // border: 1px solid red;
        // height: 1000px;

        .collectItem {
            border-radius: 20px;
            // height: 380px;
            width: 32%;
            background: #F6F8FB;
            margin-bottom: 20px;
            margin-right: 10px;
            cursor: pointer;
            position: relative;

            .img {
                border-radius: 20px 20px 0 0;
                height: 220px;
            }

            .cornermark {
                position: absolute;
                right: 10px;
                top: 10px;
                border-radius: 5px;
                background: #ffffff;
                text-align: center;
                height: 14px;
                line-height: 14px;
                font-size: 12px;
                color: #699EF2;
                padding: 3px;
            }

            .favorites-info {
                padding: 15px;
                height: 80px;

                .name {
                    font-size: 20px;
                    font-weight: 400;
                    color: #000000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .num {
                    width: 230px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .infoBox {
                    // margin-top: 35px;

                    .price {
                        color: red;
                    }
                }
            }

            .removedGoods {
                padding: 15px;
                cursor: default;
                span {
                    font-size: 20px;
                    font-weight: 400;
                    color: #000000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }
                i{
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>